<template>
<!-- 订单列表 -->
  <div class="orderListWrapper">
    <van-list v-if="orderListData.length > 0">
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    > -->
      <van-cell v-for="(item, index) in orderListData" :key="index" class="cell_list" @click.stop="lookDetails(item)">
        <ul class="orderList">
          <li class="listContent contentWrapper">
            <img src="http://b1-q.mafengwo.net/s16/M00/8D/1D/CoUBUmD5dOuAdnwYAAtBxUvuqpc075.jpg?imageMogr2%2Fthumbnail%2F%21220x150r%2Fstrip%2Fgravity%2FCenter%2Fcrop%2F%21220x150%2Fquality%2F90" alt="" class="listImg">
            <div class="content">
              <p v-text="item.goodsName" class="productName"></p>
              <p class="productTypeNumber">x{{ item.orderCount }}</p>
            </div>
            <p class="orderType">{{ item.orderStatus }}</p>
          </li>
          <li class="number_price contentWrapper">
            <span class="price">合计：￥{{ item.orderTotal }}</span>
            <span class="number">共1件商品</span>
          </li>
          <li class="btnWrapper contentWrapper">
            <div class="btn payBtn" v-if="item.orderStatus === '待付款'" @click.stop="againPay(item)">立即付款</div>
            <div class="btn" @click.stop="goLogistics(item)">查看物流</div>
            <div class="btn" @click="contact">联系客服</div>
          </li>
        </ul>
      </van-cell>
    </van-list>
    <van-list v-else class="noOrder">
      暂无订单信息
    </van-list>
  </div>
</template>

<script>
import { postOrderList, getServiceData, postAgainPayOrder } from 'api/api'
export default {
  name: 'orderList',
  props: {
    active: {
      type: String,
      default () {
        return '1'
      }
    }
  },
  data () {
    return {
      loading: false,
      finished: false,
      orderListData: [],
    }
  },
  created () {
    console.log(this.$store.state.userId)
    if (!this.$route.params.phone) {
      this.$router.replace({
        path: `/home/${this.$store.state.userId}`
      })
    }
    // this.contact()
  },
  watch: {
    active: {
      immediate: true,
      handler () {
        this.queryOrderList()
      }
    }
  },
  methods: {
    queryOrderList () {
      let params = {
        phoneNo: this.$route.params.phone,
        queryOrderStatus: Number(this.active)
      }
      postOrderList(params).then(res => {
        if (res && res.code === 200) {
          this.orderListData = res.result
        }
      })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.orderListData.push({
      //       title: '66666'
      //     })
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.orderListData.length >= 20) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
    // 联系客服
    contact () {
      let params = {
        userId: this.$store.state.userId
      }
      getServiceData(params).then(res => {
        console.log('获取客服信息', res)
      })
    },
    // 查看物流
    goLogistics () {
      this.$router.push({
        name: 'logistics'
      })
    },
    // 二次支付
    againPay (item) {
      postAgainPayOrder(item.orderNum).then(res => {
        if (res && res.code === 200) {
          // let Url = `http://huj.test.rzcf.com/result/${this.$route.params.id}`
          let Url = `${this.$store.state.localhostUrl}/result/${this.$route.params.id}`
          let redircetUrl = encodeURIComponent(Url)
          window.location.href = res.result + `&redirect_url=${redircetUrl}`
        } else if (res.code === 201) {
          this.$toast('订单超时请重新支付')
        } else {
          this.$toast(res.message)
        }
      })
    },
    // 查看订单详情
    lookDetails (item) {
      let routerName = ''
      if (item.orderStatus === '待付款') {
        // 待付款
        routerName = 'orderPay'
      } else {
        // 已完成
        routerName = 'orderDetails'
      }
      this.$router.push({
        name: routerName,
        params: {
          data: item,
          phone: this.$route.params.phone
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .orderListWrapper
    min-height 100%
    padding 10px 13px
    padding-bottom 30px
    background-color #F0F0F0
    .cell_list
      margin-bottom 10px
      border-radius 4px
      padding 0
    .orderList
      margin 10px 10px 19px 10px
      border-radius 4px
      // padding 20px
      .contentWrapper
        display flex
        .listImg
          width 30%
          height 70px
          flex 2%
        .content
          margin-left 12px
          flex 30%
          .productName
            color #333333
            font-size 14px
            font-weight 500
          .productTypeNumber
            font-size 12px
            font-weight 500
            color #999
        .orderType
          color #FF5257
          font-size 14px
          font-weight bold
      .btnWrapper, .number_price
        flex-direction row-reverse
        margin-top 15px
        .btn
          border 1px solid #E6E6E6
          color #333
          padding 0 16px
          border-radius 13px
          margin-right 10px
          font-weight 500
          &:first-child
            margin-right 0
        .payBtn
          border 1px solid #FF5257
          color #FF5257
        .number
          margin-right 25px
  .noOrder
    text-align center
    margin-top 20%
    color #666
</style>