<template>
  <div class="order">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-tabs v-model="active">
      <van-tab title="全部" name="1"></van-tab>
      <van-tab title="待付款" name="2"> </van-tab>
      <van-tab title="待发货" name="3"></van-tab>
      <van-tab title="已发货" name="4"></van-tab>
      <van-tab title="已完成" name="5"></van-tab>
    </van-tabs>
    <orderList :active="active"></orderList>
  </div>
</template>

<script>
import orderList from './modular/orderList.vue'

export default {
  name: 'order',
  data () {
    return {
      active: '1'
    }
  },
  methods: {
    onClickLeft () {
      this.$router.replace({
        path: `/home/${this.$store.state.userId}`
      })
    }
  },
  components: {
    orderList
  }
}
</script>

<style lang="stylus" scoped>
  .order
    height 100%
</style>